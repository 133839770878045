import CookieConsents from 'utils/cookieConsents'
import { getTCFConsentableContentVersion } from 'utils/tcf-gvl'

import { SOURCE_KEY } from './constants'


/**
 * See the README in this directory for instructions on how to use this.
 */
(function() {
  // This can only be used in the context of an iframe
  if ( !window.parent ) {
    return
  }

  const ccVersion = getTCFConsentableContentVersion()

  window.parent.postMessage({
    source: SOURCE_KEY,
    payload: {
      consents: CookieConsents.getConsents(),
      ccVersion,
    },
  }, '*')
})()
